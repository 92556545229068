import React, { useContext, useMemo } from 'react';

import { Alert, App, Button, Divider, Flex } from 'antd';
import { EMAIL_CONFIG_TYPE } from 'types/EmailConfigType';

import { CredentialResponse, GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input, Select, Switch } from 'formik-antd';
import { isEqual } from 'lodash';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

export interface EmailConfigFormValues {
  type: EMAIL_CONFIG_TYPE;
  host?: string;
  withSsl?: boolean;
  user?: string;
  password?: string;
  refreshToken?: string;
}

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const noReplyEmail = 'no-reply@fotostudio.io';

interface EmailConfigProps {
  isGoogleConfigured: boolean;
  handleUnlinkGoogle: () => void;
}

const ActionContainer = styled(Flex)`
  margin-top: ${Metrics.baseMargin}px;
`;

const StyledDivider = styled(Divider)`
  height: 24px;
`;

const GoogleAlert = styled(Alert)`
  margin-bottom: ${Metrics.smallMargin}px;
`;

const EmailConfig = ({
  values,
  setFieldValue,
  submitForm,
  isGoogleConfigured,
  isSubmitting,
  handleUnlinkGoogle,
  initialValues,
}: FormikProps<EmailConfigFormValues> & EmailConfigProps) => {
  const { t, locale } = useContext(LocalizationContext);
  const { message } = App.useApp();

  const login = useGoogleLogin({
    scope:
      'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/calendar',
    prompt: 'consent',
    onError: () => {
      message.error(t('auth.google.message.error'));
    },
    onSuccess: tokenResponse => {
      setFieldValue('refreshToken', tokenResponse.access_token);

      setTimeout(() => {
        submitForm();
      }, 250);
    },
  });

  const { type } = values;

  const isSubmitVisible = useMemo(() => type !== EMAIL_CONFIG_TYPE.GOOGLE, [isGoogleConfigured, type]);

  return (
    <Form layout="vertical">
      <Form.Item label={t('app.emailConfig.method')} name="type" required hasFeedback={false}>
        <Select name="type" size="large" optionFilterProp="children" getPopupContainer={trigger => trigger.parentNode}>
          <Select.Option value={EMAIL_CONFIG_TYPE.NO_REPLY} title={''}>
            {t('app.emailConfig.type.noReply', { email: noReplyEmail })}
          </Select.Option>
          <Select.Option value={EMAIL_CONFIG_TYPE.SMTP} title={''}>
            {t('app.emailConfig.type.smtp')}
          </Select.Option>
          <Select.Option value={EMAIL_CONFIG_TYPE.GOOGLE} title={''}>
            {t('app.emailConfig.type.google')}
          </Select.Option>
        </Select>
      </Form.Item>
      {type === EMAIL_CONFIG_TYPE.NO_REPLY && (
        <Alert message={t('app.emailConfig.defaultInfo', { email: noReplyEmail })} />
      )}
      {type === EMAIL_CONFIG_TYPE.SMTP && (
        <>
          <Flex align="center">
            <Form.Item label={t('app.emailConfig.host')} name="host" required hasFeedback={false}>
              <Input name="host" size="large" />
            </Form.Item>
            <Form.Item
              label={t('app.emailConfig.ssl')}
              name="withSsl"
              required
              style={{ flex: 'none' }}
              hasFeedback={false}
            >
              <Switch name="withSsl" />
            </Form.Item>
          </Flex>
          <Flex>
            <Form.Item label={t('app.common.email')} name="user" required hasFeedback={false}>
              <Input name="user" size="large" type="email" />
            </Form.Item>
            <Form.Item label={t('app.common.password')} name="password" required hasFeedback={false}>
              <Input.Password name="password" size="large" />
            </Form.Item>
          </Flex>
        </>
      )}
      {type === EMAIL_CONFIG_TYPE.GOOGLE && isGoogleConfigured && (
        <Flex align="center">
          <Button onClick={handleUnlinkGoogle}>{t('app.settings.emailConfig.google.unlink')}</Button>
          <StyledDivider type="vertical" />
          <Alert type="success" message={t('app.settings.emailConfig.google.linked')} style={{ flex: 1 }} />
        </Flex>
      )}
      {type === EMAIL_CONFIG_TYPE.GOOGLE && !isGoogleConfigured && (
        <Form.Item label={t('app.emailConfig.type.google')} name="google" required hasFeedback={false}>
          <GoogleAlert message={t('app.emailConfig.googleInfo')} />
          <Button onClick={() => login()}>{t('app.settings.emailConfig.google.signIn')}</Button>

          <Input type="hidden" name="refreshToken" />
        </Form.Item>
      )}
      {isSubmitVisible && (
        <ActionContainer justify="flex-end">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            loading={isSubmitting}
            disabled={isEqual(initialValues, values)}
          >
            {t('app.settings.emailConfig.submit')}
          </Button>
        </ActionContainer>
      )}
    </Form>
  );
};

export interface EmailConfigFormPayload {
  values: EmailConfigFormValues;
  formikBag: FormikBag<EmailConfigFormProps, EmailConfigFormValues>;
}

export interface EmailConfigFormProps extends EmailConfigProps {
  onSubmit: (payload: EmailConfigFormPayload) => void;
  defaultValues: EmailConfigFormValues;
  handleUnlinkGoogle: () => void;
}

export const EmailConfigForm = withFormik<EmailConfigFormProps, EmailConfigFormValues>({
  enableReinitialize: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: yup.object({
    type: yup.mixed<EMAIL_CONFIG_TYPE>().required(),
    refreshToken: yup.string(),
    host: yup.string().when('type', {
      is: EMAIL_CONFIG_TYPE.SMTP,
      // @ts-ignore
      then: yup.string().host().required(),
      otherwise: yup.string().notRequired(),
    }),
    withSsl: yup.boolean().when('type', {
      is: EMAIL_CONFIG_TYPE.SMTP,
      then: yup.boolean().required(),
      otherwise: yup.boolean().notRequired(),
    }),
    user: yup.string().when('type', {
      is: EMAIL_CONFIG_TYPE.SMTP,
      then: yup.string().email().required(),
      otherwise: yup.string().notRequired(),
    }),
    password: yup.string().when('type', {
      is: EMAIL_CONFIG_TYPE.SMTP,
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
  }),
})(EmailConfig);
