import Images from 'Themes/Images';

import DE from 'i18n/locales/de.json';
import EN from 'i18n/locales/en.json';
import ES from 'i18n/locales/es.json';
import FR from 'i18n/locales/fr.json';
import IT from 'i18n/locales/it.json';
import NL from 'i18n/locales/nl.json';

import { GalleryAccessPolicy } from 'Operations/__generated__/graphql';

// Group all locales in a single object
const locales: Record<string, typeof EN> = {
  en: EN,
  fr: FR,
  nl: NL,
  de: DE,
  es: ES,
  it: IT,
};

// Welcome message type
export interface WelcomeMessageTemplate {
  content: string;
  assetId?: string;
  imageUrl?: string;
}

type WelcomeMessageTemplateKey = keyof typeof locales.en.app.gallery.welcomeMessage.template.content;
type WelcomeMessageTitleKey = keyof typeof locales.en.app.gallery.welcomeMessage.template.title;

// Structure: environment -> language -> template key -> asset id
const assetIdsByEnvironment: Record<string, Record<string, Record<WelcomeMessageTemplateKey, string>>> = {
  staging: {
    fr: {
      quickGuide: '1/images/07JcCWtsthKUQfnH1d',
      includedProducts: '1/images/jNJdzHxQDL0UbUfRS1',
      favorites: '1/images/gJnbhnELgBr4rcPNir',
      downloadFiles: '1/images/fJgAHJnYeWvsDSKgyn',
      personalSpace: '1/images/G8S1q9XykAMEG1UaZb',
    },
    en: {
      quickGuide: '1/images/07JcCWtsthKUQfnH1d',
      includedProducts: '1/images/jNJdzHxQDL0UbUfRS1',
      favorites: '1/images/gJnbhnELgBr4rcPNir',
      downloadFiles: '1/images/fJgAHJnYeWvsDSKgyn',
      personalSpace: '1/images/G8S1q9XykAMEG1UaZb',
    },
    nl: {
      quickGuide: '1/images/07JcCWtsthKUQfnH1d',
      includedProducts: '1/images/jNJdzHxQDL0UbUfRS1',
      favorites: '1/images/gJnbhnELgBr4rcPNir',
      downloadFiles: '1/images/fJgAHJnYeWvsDSKgyn',
      personalSpace: '1/images/G8S1q9XykAMEG1UaZb',
    },
    de: {
      quickGuide: '1/images/07JcCWtsthKUQfnH1d',
      includedProducts: '1/images/jNJdzHxQDL0UbUfRS1',
      favorites: '1/images/gJnbhnELgBr4rcPNir',
      downloadFiles: '1/images/fJgAHJnYeWvsDSKgyn',
      personalSpace: '1/images/G8S1q9XykAMEG1UaZb',
    },
    es: {
      quickGuide: '1/images/07JcCWtsthKUQfnH1d',
      includedProducts: '1/images/jNJdzHxQDL0UbUfRS1',
      favorites: '1/images/gJnbhnELgBr4rcPNir',
      downloadFiles: '1/images/fJgAHJnYeWvsDSKgyn',
      personalSpace: '1/images/G8S1q9XykAMEG1UaZb',
    },
    it: {
      quickGuide: '1/images/07JcCWtsthKUQfnH1d',
      includedProducts: '1/images/jNJdzHxQDL0UbUfRS1',
      favorites: '1/images/gJnbhnELgBr4rcPNir',
      downloadFiles: '1/images/fJgAHJnYeWvsDSKgyn',
      personalSpace: '1/images/G8S1q9XykAMEG1UaZb',
    },
  },
  prod: {
    fr: {
      quickGuide: '1/images/nr8NGEbEdqWsg9WYCk',
      includedProducts: '1/images/wHvv9fPiTOokNKqDPj',
      favorites: '1/images/GwBeBvafvSpOOQOdto',
      downloadFiles: '1/images/zGkO875KyGPaMeAhXy',
      personalSpace: '1/images/YfWiAYLgimZF7gNM8H',
    },
    en: {
      quickGuide: '1/images/e8E9ihTRdYJr3ReG7Q',
      includedProducts: '1/images/8iCu9zJ0nAJiBYFdEx',
      favorites: '1/images/TEeYTpbzfeuQ9Bsfg8',
      downloadFiles: '1/images/XVq82rcDeJ36jxCwfP',
      personalSpace: '1/images/hqQwdwjuS9CZq3vHaf',
    },
    nl: {
      quickGuide: '1/images/VPbE2eDr4xtCpwBbWR',
      includedProducts: '1/images/4f1HGFrKUu7bVCNW32',
      favorites: '1/images/TEeYTpbzfeuQ9Bsfg8',
      downloadFiles: '1/images/XVq82rcDeJ36jxCwfP',
      personalSpace: '1/images/vFsreHdh8RdZ1asfwM',
    },
    de: {
      quickGuide: '1/images/pS0Tog7c6T3fULQ7wg',
      includedProducts: '1/images/pWSdO2sVy7x2oMyZdP',
      favorites: '1/images/TEeYTpbzfeuQ9Bsfg8',
      downloadFiles: '1/images/XVq82rcDeJ36jxCwfP',
      personalSpace: '1/images/oS30k43s1XOWBuUK6Z',
    },
    es: {
      quickGuide: '1/images/kMJumD5xuGdqACw5sV',
      includedProducts: '1/images/Pz1fNEWB5bRnn08P1w',
      favorites: '1/images/TEeYTpbzfeuQ9Bsfg8',
      downloadFiles: '1/images/XVq82rcDeJ36jxCwfP',
      personalSpace: '1/images/ZzgoBJ5rqeQzKdQ4Rg',
    },
    it: {
      quickGuide: '1/images/Ens7uQ1Jbr3MO4eLBv',
      includedProducts: '1/images/g0YUE1v19c3ehNKYcv',
      favorites: '1/images/TEeYTpbzfeuQ9Bsfg8',
      downloadFiles: '1/images/XVq82rcDeJ36jxCwfP',
      personalSpace: '1/images/DD951EALHg83MExz01',
    },
  },
};

const getCurrentAssetIds = (locale: string = 'en'): Record<WelcomeMessageTemplateKey, string> => {
  const env = process.env.NODE_ENV === 'production' ? 'prod' : 'staging';
  const assetIds = assetIdsByEnvironment[env];

  // Use language-specific assets if available, otherwise fall back to default
  return {
    ...(assetIds[locale] || {}),
  };
};

const getTemplateImages = (
  locale: string = 'en',
): Record<WelcomeMessageTemplateKey, { assetId?: string; imageUrl?: string }> => {
  const currentAssetIds = getCurrentAssetIds(locale);

  // Check if localized images exist for this locale, otherwise fall back to English
  const getImageUrl = (key: WelcomeMessageTemplateKey) => {
    // Try to access the image with the given locale
    const formattedLocale = locale.toUpperCase() as keyof typeof Images.welcomeMessage;
    if (Images.welcomeMessage?.[formattedLocale]?.[key]) {
      return Images.welcomeMessage[formattedLocale][key];
    } else {
      return Images.welcomeMessage.EN[key];
    }
  };

  return {
    quickGuide: {
      assetId: currentAssetIds.quickGuide,
      imageUrl: getImageUrl('quickGuide'),
    },
    includedProducts: {
      assetId: currentAssetIds.includedProducts,
      imageUrl: getImageUrl('includedProducts'),
    },
    favorites: {
      assetId: currentAssetIds.favorites,
      imageUrl: getImageUrl('favorites'),
    },
    downloadFiles: {
      assetId: currentAssetIds.downloadFiles,
      imageUrl: getImageUrl('downloadFiles'),
    },
    personalSpace: {
      assetId: currentAssetIds.personalSpace,
      imageUrl: getImageUrl('personalSpace'),
    },
  };
};

// Cache for template images by locale
const templateImagesCache: Record<string, ReturnType<typeof getTemplateImages>> = {};

const getTemplateImagesForLocale = (locale: string = 'en') => {
  if (!templateImagesCache[locale]) {
    templateImagesCache[locale] = getTemplateImages(locale);
  }
  return templateImagesCache[locale];
};

// Welcome message template group type
export interface WelcomeMessageTemplateGroup {
  id: string;
  name: string;
  messages: WelcomeMessageTemplate[];
  // Defines which policies can use this template (if empty, all can use it)
  applicablePolicies?: GalleryAccessPolicy[];
}

// Type for templates by locale
export type LocalizedWelcomeMessageTemplates = Record<string, WelcomeMessageTemplateGroup[]>;

// Helper to create a message template based on locale
const createMessageTemplate = ({
  templateKey,
  locale,
}: {
  templateKey: WelcomeMessageTemplateKey;
  locale: string;
}): WelcomeMessageTemplate => {
  const templateImages = getTemplateImagesForLocale(locale);
  return {
    content: locales[locale].app.gallery.welcomeMessage.template.content[templateKey],
    assetId: templateImages[templateKey].assetId,
    imageUrl: templateImages[templateKey].imageUrl,
  };
};

// Create a template group based on locale
const createTemplateGroup = ({
  id,
  locale,
  messageKeys,
}: {
  id: WelcomeMessageTitleKey;
  locale: string;
  messageKeys: WelcomeMessageTemplateKey[];
}): WelcomeMessageTemplateGroup => {
  return {
    id,
    name: locales[locale].app.gallery.welcomeMessage.template.title[id],
    messages: messageKeys.map(key => createMessageTemplate({ templateKey: key, locale })),
  };
};

// Template definitions
const getTemplateGroups = ({
  locale,
}: {
  locale: string;
}): Record<WelcomeMessageTitleKey, WelcomeMessageTemplateGroup> => ({
  quickGuide: createTemplateGroup({
    id: 'quickGuide',
    locale,
    messageKeys: ['quickGuide'],
  }),
  fullGuide: createTemplateGroup({
    id: 'fullGuide',
    locale,
    messageKeys: ['includedProducts', 'favorites', 'downloadFiles', 'personalSpace'],
  }),
});

// Templates for public galleries
const getPublicTemplates = ({ locale }: { locale: string }): WelcomeMessageTemplateGroup[] => {
  const templates = getTemplateGroups({ locale });
  return [templates['quickGuide'], templates['fullGuide']];
};

// Templates for private galleries (with access code)
const getPrivateTemplates = ({ locale }: { locale: string }): WelcomeMessageTemplateGroup[] => {
  const templates = getTemplateGroups({ locale });
  return [templates['fullGuide'], templates['quickGuide']];
};

// Get all available templates for a locale
export const getAllWelcomeMessageTemplates = ({
  locale = 'en',
}: {
  locale?: string;
} = {}): Record<GalleryAccessPolicy, WelcomeMessageTemplateGroup[]> => {
  const publicTemplates = getPublicTemplates({ locale });
  const privateTemplates = getPrivateTemplates({ locale });

  return {
    [GalleryAccessPolicy.PUBLIC]: publicTemplates,
    [GalleryAccessPolicy.ACCESS_CODE]: privateTemplates,
    [GalleryAccessPolicy.OLD_PUBLIC]: publicTemplates,
    [GalleryAccessPolicy.OLD_ACCESS_CODE]: privateTemplates,
  };
};

// Get templates based on locale and access policy
export const getWelcomeMessageTemplates = ({
  locale = 'en',
  accessPolicy = GalleryAccessPolicy.PUBLIC,
}: {
  locale?: string;
  accessPolicy?: GalleryAccessPolicy;
} = {}): WelcomeMessageTemplateGroup[] => {
  // Select appropriate template list based on policy
  if ([GalleryAccessPolicy.PUBLIC, GalleryAccessPolicy.OLD_PUBLIC].includes(accessPolicy)) {
    return getPublicTemplates({ locale });
  } else {
    return getPrivateTemplates({ locale });
  }
};
