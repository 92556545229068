import React, { useContext, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { GoogleOAuthProvider } from '@react-oauth/google';

import GalleryAppIFrame from 'Components/Molecules/GalleryAppIFrame';

import AuthGate from 'Pages/Auth/AuthGate';
import NotFound from 'Pages/Errors/NotFound';

import AppNavigation from 'Navigation/AppNavigation';
import AuthNavigation from 'Navigation/AuthNavigation';
import BootNavigation from 'Navigation/BootNavigation';
import SlipControlNavigation from 'Navigation/SlipControlNavigation';

import { LocalizationContext } from 'i18n';

import { ApolloContext } from 'Providers/ApolloProvider';

import { GalleryVisitsProvider } from 'Hooks/GalleryApp';
import { useRoles } from 'Hooks/useRoles';

import { onAppLoading } from 'Services/AppLifeCycle';

import { GET_AUTH } from 'Operations/Queries/Auth/GetAuth';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

interface Props {
  children: JSX.Element;
  redirectCondition: boolean[];
  redirect: string[];
}

function RequireAuth({ children, redirectCondition, redirect }: Props) {
  const redirectIndex = redirectCondition.findIndex(cdn => cdn);

  return redirectIndex < 0 ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: redirect[redirectIndex],
        // TODO: get from state
        // state: { from },
      }}
    />
  );
}

const RootNavigation = () => {
  const { data } = useQuery(GET_AUTH);
  const { setLocale } = useContext(LocalizationContext);
  const { client, isPublicPage } = useContext(ApolloContext);
  const { isLabo } = useRoles();

  const isLoggedIn = data?.auth?.isLoggedIn || false;
  const isOnboarded = true;

  useEffect(() => {
    const initApp = async () => {
      if (client) {
        await onAppLoading({ client, setLocale, isPublicPage, isLoggedIn });
      }
    };

    initApp();
  }, [client, setLocale, isPublicPage, isLoggedIn]);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GalleryVisitsProvider>
        <GalleryAppIFrame />
        <BrowserRouter>
          <Routes>
            <Route
              path="auth/*"
              element={
                <RequireAuth
                  redirect={['/onboarding', '/app/orders/fotostudio', '/app/dashboard']}
                  redirectCondition={[isLoggedIn && !isOnboarded, isLoggedIn && isLabo, isLoggedIn && isOnboarded]}
                >
                  <AuthNavigation />
                </RequireAuth>
              }
            />
            <Route
              path="slip-control/*"
              element={
                <RequireAuth redirect={['/auth/signin']} redirectCondition={[!isLoggedIn, isLoggedIn && !isOnboarded]}>
                  <SlipControlNavigation />
                </RequireAuth>
              }
            />
            <Route
              path="app/*"
              element={
                <RequireAuth
                  redirect={['/auth/signin', '/onboarding']}
                  redirectCondition={[!isLoggedIn, isLoggedIn && !isOnboarded]}
                >
                  <AppNavigation />
                </RequireAuth>
              }
            />
            <Route index element={<BootNavigation loggedIn={isLoggedIn} onboarded={isOnboarded} isLabo={isLabo} />} />
            <Route path="auth-gate" element={<AuthGate />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </GalleryVisitsProvider>
    </GoogleOAuthProvider>
  );
};

export default RootNavigation;
